export const colorArray = [
  "#b3cae5",
  "#dbdde4",
  "#e4e3e4",
  "#f7ddbb",
  "#efcab2",
  "#bccacc",
  "#c7d8d6",
  "#d9ebe0",
  "#ebf9e3",
  "#f4f8d0",
  "#5e7fb1",
  "#dce8f7",
  "#eff1f4",
  "#fce1a8",
  "#f7ec86",
  "#8fb8ee",
  "#cbe2f4",
  "#dbe5eb",
  "#f9d3b8",
  "#e0b2a3",
  "#a2e0f9",
  "#cef5fc",
  "#eafaeb",
  "#fefcd3",
  "#fdf4ba",
  "#6bafd2",
  "#a4c8dc",
  "#d6cbca",
  "#eabc96",
  "#db8876",
  "#b4ced8",
  "#d7e5d4",
  "#e2e8c9",
  "#f1e5b9",
  "#edd7ac",
  "#657489",
  "#bfb6aa",
  "#ead79d",
  "#f2ebda",
  "#adacb2",
  "#eac3a2",
  "#8d7b6c",
  "#cc9d7a",
  "#fff9aa",
  "#525f83",
  "#848896",
  "#bb9d78",
  "#f6e183",
  "#ffe3c8",
  "#efad9e",
  "#c79797",
  "#a78a92",
  "#857d8d",
  "#6f749e",
  "#9a8daf",
  "#d0a8b9",
  "#f8bbb1",
  "#fde6b1",
  "#536a97",
  "#8087ad",
  "#bca391",
  "#bd968a",
  "#a38b8a",
  "#7b9ea7",
  "#9baf93",
  "#dbaf81",
  "#fbdf73",
  "#727288",
  "#8e889b",
  "#d3c2bd",
  "#f9d89a",
  "#f8c785",
  "#506e90",
  "#7695aa",
  "#a7bdb8",
  "#e2e2b8",
  "#fdf998",
  "#868080",
  "#b7b29b",
  "#dfd6a4",
  "#e9f3a2",
  "#7e74b2",
  "#b3a2c2",
  "#e2cdbe",
  "#f6cf97",
  "#f4a77a",
  "#34a4ca",
  "#59d7dd",
  "#a8f2f0",
  "#d0f8ef",
  "#d6f6e1",
  "#7696cd",
  "#8fb2e4",
  "#b0cff0",
  "#d7e5ec",
  "#dee0e7",
  "#8dd6c3",
  "#c5e5e2",
  "#eafaeb",
  "#f9f7ca",
  "#fceea1",
  "#4e72c7",
  "#6d9ed7",
  "#a4c8d5",
  "#b4d9e1",
  "#c4d9d6",
  "#947461",
  "#f98056",
  "#f7ec86",
  "#95b3bf",
  "#c6cdd3",
  "#e5d8d9",
  "#f1e1d9",
  "#f3e1cd",
  "#4c86ab",
  "#95a5bc",
  "#bfcdc9",
  "#dcd6c9",
  "#edd9c7",
  "#758391",
  "#e5e3b0",
  "#889db6",
  "#a5b8ce",
  "#c1cfdd",
  "#dee1e4",
  "#d5d1cf",
  "#74bddb",
  "#a8d1eb",
  "#cddbf5",
  "#e4e6fb",
  "#f6f4f8",
  "#a7d3cb",
  "#bcc1c4",
  "#e5cab3",
  "#fee6c5",
  "#fdecd0",
  "#8e9fa4",
  "#decab2",
  "#f2d580",
  "#ffa642",
  "#c5d4d7",
  "#d6b98d",
  "#c99262",
  "#8c5962"
];
