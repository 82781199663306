import React, { useState } from 'react'
import { Button, Label } from 'semantic-ui-react';
import VNFInputs from './VNFInputs';
import { colorArray } from "../../assets/styles/colors";
import style from "../../assets/styles/responsive.module.css";


const MAXCPU = 256;

export default function VNFCalculator({ 
    vnfs, servers, setServers, setCputopNew, buttonRef, 
    setError, setExternalStorage, setVNFcount,
    cputop, setCputop, rescpu, HT, numaNodes,
    sriovbw, mem, disk,freeServers,ports, portsSwitch,
    setDisk, setFreeServers, setHT, setMem, setNumaNodes, setPorts,
    setPortsSwitch, setRescpu, setSriovbw, onDeleteError
    }) {

    const vnfCountArray = [];

    const handleDeleteError = () => {
        onDeleteError();
    }

    const csvFields = ["id"
        , "vnf"
        , "name"
        , "number"
        , "cpus"
        , "ram"
        , "anti_affinity"
        , "sriov_throughput_vm"
        , "local_disk"
        , "external_disk"]

    
    // const [cputopNew, setCputopNew] = useState(44);


    const validateAntiAffinity = (antiAffinityList, vnfAntiAffinity) => {

        const arrayvnfAntiAffinity = vnfAntiAffinity.split(",")

        return antiAffinityList.some(item => arrayvnfAntiAffinity.includes(item))
    }

    const placement = (
        vnfs,
        numaNodesInput,
        perNUMAVCPUinput,
        sriovbwInput,
        memInput,
        diskInput
    ) => {
        setError("");
        setServers([]);

        const calculatedServers = [];
        let calculatedExternalDisk = 0;
        const colors = colorArray
            .sort(() => 0.5 - Math.random())
            .slice(0, vnfs.length);

        vnfs.forEach((vnfData, index) => {
            const vnf = vnfData.data;

            // Build array for counting unique VNFs
            if (vnf.vnf && !vnfCountArray.includes(vnf.vnf)) {
                vnfCountArray.push(vnf.vnf);
            }
            const vmCPU = parseInt(vnf.cpus);
            const vmSRIOVBW = parseInt(vnf.sriov_throughput_vm);
            const vmMem = parseInt(vnf.ram);
            const vmDisk = vnf.local_disk ? parseInt(vnf.local_disk) : 0;
            // for each VM in VNF

            for (let i = 1; i < parseInt(vnf.number) + 1; i++) {
                // build VM object
                const vmObject = {
                    id: vnf.id,
                    name: vnf.vnf + "-" + vnf.name + "-" + i,
                    color: colors[index]
                };

                let foundServer = false;
                let foundNuma = false;
                // try to place it in an existing server
                if (calculatedServers.length) {
                    for (let j = 0; j < calculatedServers.length; j++) {
                        if (!validateAntiAffinity(calculatedServers[j].antiAffinityList, vnf.anti_affinity)) {
                            // Affinity not on this server, try placement in each NUMA node
                            for (let k = 0; k < calculatedServers[j].numaArray.length; k++) {
                                if (
                                    vmCPU <= calculatedServers[j].numaArray[k].cpusLeft &&
                                    vmMem <= calculatedServers[j].memLeft &&
                                    vmDisk <= calculatedServers[j].diskLeft &&
                                    (vmSRIOVBW <= calculatedServers[j].numaArray[k].sriovbwLeft ||
                                        !vmSRIOVBW)
                                ) {
                                    // Computing available, place it!
                                    calculatedServers[j].numaArray[k].cpus = calculatedServers[
                                        j
                                    ].numaArray[k].cpus.concat(Array(vmCPU).fill(vmObject));
                                    // Mark server with anti-affinity group
                                    if (vnf.anti_affinity) {
                                        vnf.anti_affinity.split(",").forEach(e => {
                                            calculatedServers[j].antiAffinityList.push(e);
                                        })

                                    }
                                    // Update CPUs left
                                    calculatedServers[j].numaArray[k].cpusLeft -= vmCPU;
                                    // Update SRIOV BW left
                                    if (vmSRIOVBW) {
                                        calculatedServers[j].numaArray[k].sriovbwLeft -= vmSRIOVBW;
                                    }
                                    // Update Mem left
                                    calculatedServers[j].memLeft -= vmMem;
                                    // Update Disk left
                                    calculatedServers[j].diskLeft -= vmDisk;
                                    // Add to external disk
                                    if (vnf.external_disk) {
                                        calculatedExternalDisk += parseFloat(vnf.external_disk);
                                    }
                                    // Found NUMA for this VM!
                                    foundNuma = true;
                                    // Break NUMA loop
                                    break;
                                }
                            }
                            // end of NUMA loop
                        }
                        if (foundNuma === true) {
                            // Found server for this VM!
                            foundServer = true;
                            // Break server loop
                            break;
                        }
                    }
                    // end of existing servers loop
                }
                // if no existing server-numa found, place it in a new server
                if (!calculatedServers.length || !foundServer) {


                    let numaArray = [];
                    for (let i = 0; i < numaNodesInput; i++) {
                        numaArray.push({
                            cpus: [],
                            cpusLeft: perNUMAVCPUinput,
                            sriovbwLeft: parseInt(sriovbwInput)
                        })
                    }


                    numaArray[0].cpus = Array(vmCPU).fill(vmObject);
                    numaArray[0].cpusLeft = perNUMAVCPUinput - vmCPU;
                    if (vmSRIOVBW) {
                        if (vmSRIOVBW > numaArray[0].sriovbwLeft) {
                            setError(
                                "Not enough SR-IOV Bandwidth available, could not complete mapping."
                            );
                            setServers([]);
                            return;
                        }
                        numaArray[0].sriovbwLeft = parseInt(sriovbwInput) - vmSRIOVBW;
                    }

                    // add NUMA with VM to new server
                    calculatedServers.push({
                        antiAffinityList: vnf.anti_affinity ? vnf.anti_affinity.split(",") : [],
                        numaArray,
                        memLeft: memInput - vmMem,
                        diskLeft: diskInput - vmDisk
                    });


                    // Add to external disk
                    if (vnf.external_disk) {
                        calculatedExternalDisk += parseFloat(vnf.external_disk);
                    }


                }
                // end of VM loop
            }
            // end of VNF loop
        });



        calculatedServers.forEach(server => {
            server.numaArray.forEach(numaNode => {
                // numaNode.cpus = numaNode.cpus.concat(Array(2).fill("R"));
                numaNode.cpus = numaNode.cpus.concat(Array(numaNode.cpusLeft).fill(""));
            });
        });
        setCputopNew(perNUMAVCPUinput);
        setServers(calculatedServers);
        setExternalStorage(calculatedExternalDisk);
        setVNFcount(vnfCountArray.length);
        return {
            servers: calculatedServers,
            externalStorage: calculatedExternalDisk
        };
    };

    const validatePayload = (vnfsInput) => {
        let result = true
        let object = vnfsInput[0].data;
        for (var property in object) {
            if (csvFields.find(e => e === property) === undefined) {
                result = false
            }
        }
        return result
    }

    const calculate = vnfsInput => {

        handleDeleteError()

        if (vnfsInput.length === 0) {
            setError(`Empty csv`);
            return;
        }

        if (vnfsInput.length > 0 && !validatePayload(vnfsInput)) {
            setError(`Invalid csv`);
            return;
        }

        if (!vnfsInput.length) {
            setError("No VNFs to process.");
            return;
        }
        if (cputop > MAXCPU) {
            setError(`Too much CPUs, reducing to ${MAXCPU}.`);
            setCputop(MAXCPU);
            return;
        }

        try {
            // Calculate placement!
            const perNUMAVCPU = (cputop - rescpu) * (HT ? 2 : 1);
            return placement(vnfsInput, numaNodes, perNUMAVCPU, sriovbw, mem, disk);
        } catch (e) {
            setError("Not enough CPUs.");
            console.log(e);
        }
    };

    return (
        <div className={style.calculator_container}>
            <VNFInputs
                HT={HT}
                cputop={cputop}
                disk={disk}
                freeServers={freeServers}
                mem={mem}
                numaNodes={numaNodes}
                ports={ports}
                portsSwitch={portsSwitch}
                rescpu={rescpu}
                setCputop={setCputop}
                setDisk={setDisk}
                setFreeServers={setFreeServers}
                setHT={setHT}
                setMem={setMem}
                setNumaNodes={setNumaNodes}
                setPorts={setPorts}
                setPortsSwitch={setPortsSwitch}
                setRescpu={setRescpu}
                setSriovbw={setSriovbw}
                sriovbw={sriovbw}

            />

            {/* 
                Button is called by reference on App.js, to avoid having calculate function on App.js
                It has to be done this way to be able to display VNF and CNF button next to each other
            */}
            <Button
                ref={buttonRef}
                style={{ width: "130px", margin: "1rem 0.5rem", padding: '0.9rem 0', display: 'none' }}
                positive
                content="Calculate VNF"
                onClick={() => {
                    calculate(vnfs);
                }}
            />

        </div>
    )
}
