import React, { useState } from 'react'
import { Label, Input, Header, Button, Form } from "semantic-ui-react";
import InputWrapper from '../layout/InputWrapper';

export default function CNFInputs({ handleDataChange, workerData }) {

    return (
        <div style={{ fontSize: "17px", padding: "1.3rem", borderRadius: "8px", margin: "10px 0px 0 0px", backgroundColor: "#fff" }}>
            <Header style={{}}>
                K8s Worker parameters

            </Header>
            <Form style={{ marginTop: "1rem", display: "flex", flexWrap: "wrap", justifyContent: "space-between"  }}>

                <InputWrapper>
                    <Label
                        basic
                        size={"large"}
                        style={{ borderWidth: 0, padding: "0 0.5rem" }}
                    >
                        CPUs
                    </Label>
                    <Input
                        list="vCPUs"
                        placeholder="vCPUs"
                        name={'vCPUs'}
                        style={{ width: "5rem", margin: "5px 0" }}
                        value={workerData['vCPUs']}
                        onChange={handleDataChange}
                    />
                    <datalist id="vCPUs">
                        <option value="12">12</option>
                        <option value="14">14</option>
                        <option value="18">18</option>
                        <option value="20">20</option>
                        <option value="22">22</option>
                        <option value="24">24</option>
                        <option value="26">26</option>
                        <option value="32">32</option>
                        <option value="64">64</option>
                    </datalist>
                </InputWrapper>
                <br />
                <InputWrapper>
                    <Label
                        basic
                        size={"large"}
                        style={{ borderWidth: 0, padding: "0 0.5rem" }}
                    >
                        Memory(GB)
                    </Label>
                    <Input
                        style={{ width: "5rem", margin: "5px 0" }}
                        value={workerData['mem']}
                        name={'mem'}
                        onChange={handleDataChange}
                    />

                </InputWrapper>
                <InputWrapper>
                    <Label
                        basic
                        size={"large"}
                        style={{ borderWidth: 0, padding: "0 0.5rem" }}
                    >
                        Disk(GB)
                    </Label>
                    <Input
                        style={{ width: "5rem", margin: "5px 0" }}
                        value={workerData['disk']}
                        name={'disk'}
                        onChange={handleDataChange}
                    />

                </InputWrapper>
                <InputWrapper>
                    <Label
                        basic
                        size={"large"}
                        style={{ borderWidth: 0, padding: "0 0.5rem" }}
                    >
                        Reserved CPUs
                    </Label>
                    <Input
                        list="vCPUsreserved"
                        placeholder="reservados"
                        style={{ width: "5rem", margin: "5px 0" }}
                        value={workerData['reservedvCPUs']}
                        name={'reservedvCPUs'}
                        onChange={handleDataChange}
                    />
                    <datalist id="vCPUsreserved">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="4">4</option>
                    </datalist>

                </InputWrapper>
                <InputWrapper>
                    <Label
                        basic
                        size={"large"}
                        style={{ borderWidth: 0, padding: "0 0.5rem" }}
                    >
                        Reserved memory(GB)
                    </Label>
                    <Input
                        style={{ width: "5rem", margin: "5px 0" }}
                        value={workerData['reservedMem']}
                        name={'reservedMem'}
                        onChange={handleDataChange}
                    />
                </InputWrapper>
                <InputWrapper>
                    <Label
                        basic
                        size={"large"}
                        style={{ borderWidth: 0, padding: "0 0.5rem" }}
                    >
                        External Disk
                    </Label>
                    <Input
                        list="externalDisk"
                        placeholder="reservados"
                        style={{ width: "5rem", margin: "5px 0" }}
                        value={workerData['externalDisk']}
                        name={'externalDisk'}
                        onChange={handleDataChange}
                    />
                    <datalist id="externalDisk">
                        <option value="1">Cinder</option>
                        <option value="2">Longhorn</option>
                        <option value="3">Local</option>
                    </datalist>

                </InputWrapper>

            </Form>
        </div>
    )
}
