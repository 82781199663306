import React from 'react'
import { Popup, Table } from 'semantic-ui-react';

export default function VNFLayout({ servers, cputopNew }) {

    const renderTableRow = (server, index) => {
        return (
            <Table.Row>
                <Table.Cell className="thinfo">server-{index + 1}</Table.Cell>
                <Popup
                    content={server.antiAffinityList.join(",")}
                    key={server.antiAffinityList}
                    trigger={
                        <Table.Cell className="thinfo">
                            {server.antiAffinityList.join(",")}
                        </Table.Cell>
                    }
                />

                {server.numaArray.map(numaNode => {
                    return (
                        <>
                            {numaNode.cpus.map((cpu, index) => {
                                return cpu ? (
                                    <Popup
                                        content={cpu.name}
                                        key={index + cpu.name}
                                        trigger={
                                            <Table.Cell
                                                className="thdata"
                                                style={{ backgroundColor: cpu.color }}
                                            >
                                                {cpu.id}
                                            </Table.Cell>
                                        }
                                    />
                                ) : (
                                    <Table.Cell className="thdata">-</Table.Cell>
                                );
                            })}
                            <Table.Cell className="thdata" style={{ fontSize: "0.9rem" }}>
                                {numaNode.sriovbwLeft}
                            </Table.Cell>
                        </>
                    );
                })}
                <Table.Cell className="thdata" style={{ fontSize: "0.9rem" }}>
                    {server.memLeft}
                </Table.Cell>
                <Table.Cell className="thdata" style={{ fontSize: "0.9rem" }}>
                    {server.diskLeft}
                </Table.Cell>
            </Table.Row>
        );
    };
    return (
        <div>
            <Table celled striped fixed id="table1">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell className="thinfo titles thblack" colSpan="1">
                            Servers ({servers.length})
                        </Table.HeaderCell>
                        <Table.HeaderCell className="thinfo titles thblack" colSpan="1">
                            Anti-Affinity
                        </Table.HeaderCell>
                        {servers.length ? (
                            <>
                                {servers[0].numaArray.map((node, index) => {
                                    return (
                                        <>
                                            <Table.HeaderCell
                                                className="titles thblack"
                                                colSpan={cputopNew}
                                            >
                                                NUMA Nodes {index} ({cputopNew})
                                            </Table.HeaderCell>
                                            <Table.HeaderCell className="titles thblack thinner">
                                                SR-IOV BW Left
                                            </Table.HeaderCell>
                                        </>
                                    );
                                })}
                                <Table.HeaderCell className="titles thblack thinner">
                                    RAM Left (GB)
                                </Table.HeaderCell>
                                <Table.HeaderCell className="titles thblack thinner">
                                    Disk Left (GB)
                                </Table.HeaderCell>
                            </>
                        ) : (
                            <Table.HeaderCell className="titles thblack" colSpan={cputopNew}>
                                NUMA Nodes
                            </Table.HeaderCell>
                        )}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {servers.map((server, index) => {
                        return renderTableRow(server, index);
                    })}
                </Table.Body>
            </Table>
        </div>
    )
}
