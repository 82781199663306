import React, { useRef, useState } from "react";
import {
  Label,
  Button,
} from "semantic-ui-react";
import "../assets/styles/table.css";
import CSVReaderComponent from "./CSVReaderComponent";
import VNFInfra from "./vnf/VNFInfra";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ReactGA from 'react-ga';

import CNFCalculator from "./cnf/CNFCalculator";
import CNFInfra from "./cnf/CNFInfra";
import CNFLayout from "./cnf/CNFLayout";
import VNFCalculator from "./vnf/VNFCalculator";
import VNFLayout from "./vnf/VNFLayout";
import CalculatorsLayout from "./layout/CalculatorsLayout";

if (window.__RUNTIME_CONFIG__.REACT_RT_GA_MEASUREMENT_ID !== "") {
  ReactGA.initialize(window.__RUNTIME_CONFIG__.REACT_RT_GA_MEASUREMENT_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);
}

const App = () => {

  // VNFs data
  const vnfCalculatorButtonRef = useRef()

  const [servers, setServers] = useState([]);
  const [cputopNew, setCputopNew] = useState(44);
  const [vnfCputopNew, setvnfCpuTopNew] = useState(44);

  const [externalStorage, setExternalStorage] = useState(0);
  const [NFS, setNFS] = useState([])
  const [VNFcount, setVNFcount] = useState([]);
  const [vnfError, setVnfError] = useState("");
  const [numaNodes, setNumaNodes] = useState(2);
  const [cputop, setCputop] = useState(22);
  const [sriovbw, setSriovbw] = useState(25);
  const [rescpu, setRescpu] = useState(2);
  const [mem, setMem] = useState(1000);
  const [disk, setDisk] = useState(5000);
  const [ports, setPorts] = useState(8);
  const [portsSwitch, setPortsSwitch] = useState(48);
  const [freeServers, setFreeServers] = useState(0);
  const [HT, setHT] = useState(true);

  /**
   * CNFs 
   * data
   */
  const [
    workerData, setWorkerData]
    = useState({
      vCPUs: 36,
      mem: 500,
      disk: 250,
      reservedvCPUs: 2,
      reservedMem: 10,
      totalvCPUs: 0,
      externalDisk: "",
    })

  const [cnfError, setCnfError] = useState(null)
  const cnfCalculatorButtonRef = useRef();
  const [workers, setWorkers] = useState([])

  const handleWorkerDataChange = (event) => {
    setWorkerData(prev => {
      return ({
        ...prev,
        [event.target.name]: event.target.value

      })
    })

  }

  const handleDeleteErrors = () => {
    setCnfError("")
    setVnfError("")
  }
  // Global handler for NFs data, the function to be called depends on the
  // content of the uploaded file
  // Data is validated on each calculator
  const handleUploadFile = data => {
    setNFS(data);
  };

  return (
    <div
      className="ui mainTable"
      style={{ width: "100%", margin: "auto", paddingBottom: "5rem" }}
    >

      <div id="block container" style={{ backgroundColor: "#fff", display: "flex", alignContent: "center", justifyContent: "center",width: "100%", padding: "0 2rem" }} >
        <h1 style={{ margin: "1em 0", textAlign: "left", flexBasis: "50%", display: "flex", alignItems: "center" }}>
          <span style={{ color: "#1e90ff" }}>NET</span>ROMINÓ
          <Label color={"orange"} size={"x-small"} style={{ marginLeft: "20px" }}>
            {`${process.env.REACT_APP_VERSION}`}
          </Label>
        </h1>
        <div id="logo" style={{ flexBasis: "50%", textAlign: "right", display: "flex", justifyContent: "end" }} >
          <img src={process.env.PUBLIC_URL + "/whitestack_blue.png"} alt="Whitestack logo" style={{ maxWidth: "25%", minHeight: "25%", objectFit: "contain", minWidth: "100px" }} />
        </div>
      </div>
      <div style={{ margin: "auto", width: "93%" }}>
        <div style={{ marginTop: "40px"}}>
          <CSVReaderComponent uploadFile={handleUploadFile} />
        </div>

        <CalculatorsLayout>
          <VNFCalculator
            onDeleteError={handleDeleteErrors}
            setExternalStorage={setExternalStorage}
            setError={setVnfError}
            buttonRef={vnfCalculatorButtonRef}
            setCputopNew={setvnfCpuTopNew}
            servers={servers}
            setServers={setServers}
            vnfs={NFS}
            setVNFcount={setVNFcount}
            cputop={cputop}
            setCputop={setCputop}
            rescpu={rescpu}
            HT={HT}
            numaNodes={numaNodes}
            sriovbw={sriovbw}
            mem={mem}
            disk={disk}
            freeServers={freeServers}
            ports={ports}
            portsSwitch={portsSwitch}
            setDisk={setDisk}
            setFreeServers={setFreeServers}
            setMem={setMem}
            setNumaNodes={setNumaNodes}
            setPorts={setPorts}
            setPortsSwitch={setPortsSwitch}
            setRescpu={setRescpu}
            setSriovbw={setSriovbw}
            setHT={setHT}
            />


          <CNFCalculator
            sriovBwInput={sriovbw}
            onDeleteError={handleDeleteErrors}
            setError={setCnfError}
            buttonRef={cnfCalculatorButtonRef}
            workers={workers} cnfs={NFS}
            workerData={workerData}
            onWorkersChange={setWorkers}
            onWorkerDataChange={handleWorkerDataChange}
            setCputopNew={setCputopNew}
            setVnfCputopNew={setvnfCpuTopNew}
            set
            setServers={setServers}
            setExternalStorage={setExternalStorage}
            numaNodes={numaNodes}
            cpuTop={cputop}
            rescpu={rescpu}
            HT={HT}
            serverDiskInput={disk}
            serverMemInput={mem}
          />
        </CalculatorsLayout>
        <Button
          style={{ width: "130px", margin: "1rem 0.5rem 1rem 0", padding: '0.9rem 0' }}
          positive
          content="Calculate CNF"
          onClick={() => {
            cnfCalculatorButtonRef.current.handleClick()
          }}
        />
        {
          cnfError ? (
            <Label style={{ marginTop: "1rem" }} color={"red"}>
              {cnfError}
            </Label>
          ) : null
        }
        <Button
          style={{ width: "130px", margin: "1rem 0.5rem", padding: '0.9rem 0' }}
          positive
          content="Calculate VNF"
          onClick={() => {
            vnfCalculatorButtonRef.current.handleClick()
          }}
        />
        {
          vnfError ? (
            <Label style={{ marginTop: "1rem" }} color={"red"}>
              {vnfError}
            </Label>
          ) : null
        }
        <div>

          <ReactHTMLTableToExcel
            id="table-xls-button"
            className="ui secondary button"
            table="table1"
            filename="netromino_export"
            sheet="data"
            buttonText="Download as XLS"

          />
        </div>
      </div>
      <div style={{ margin: "40px auto", width: "93%"}}>


        {
          NFS.length > 0 &&
            Object.keys(NFS[0].data).includes('cnf') &&
            workers && workers.length > 0 ?
            <CNFInfra externalStorage={externalStorage} numServers={servers ? servers.length : 0} numWorkers={workers ? workers.length : 0} /> : ''
        }

        {
          NFS.length > 0 &&
            Object.keys(NFS[0].data).includes('cnf') &&
            workers && workers.length > 0 ?
            <CNFLayout cputopNew={vnfCputopNew} servers={servers} workerCPU={workerData.totalvCPUs} workers={workers} /> : ''
        }
        {
          NFS.length > 0 &&
            Object.keys(NFS[0].data).includes('vnf') &&
            servers.length ?
            (<VNFInfra
              servers={servers}
              ports={ports}
              portsSwitch={portsSwitch}
              externalStorage={externalStorage}
              freeServers={freeServers}
              vnfs={VNFcount}
            />)
            : ''
        }
        {
          NFS.length > 0 &&
            Object.keys(NFS[0].data).includes('vnf') &&
            servers.length ?
            (
              <VNFLayout cputopNew={vnfCputopNew} servers={servers} />
            )
            : ''
        }
      </div>
    </div>
  );
};

export default App;
