import React, { useState } from 'react'
import { Popup, Table } from 'semantic-ui-react';
import VNFLayout from '../vnf/VNFLayout';

export default function CNFLayout({ workers, workerCPU, servers , cputopNew}) {

    const [colSpan, setColSpan] = useState(null);

    const renderTableRow = (server, index) => {
        return (
            <Table.Row id={index}>
                <Table.Cell className="thinfo">worker-{index + 1}</Table.Cell>
                <Popup
                    content={server.antiAffinityList.map(item => item.group).join(',')}
                    key={server.antiAffinityList}
                    trigger={
                        <Table.Cell className="thinfo">
                            {server.antiAffinityList.map(item => item.group).join(',')}
                        </Table.Cell>
                    }
                />

                {
                    (() => {
                        const items = []
                        let totalCpu = 0
                        let nodeCpus = 0;
                        for (let i = 0; i < server.podArray.length; i++) {
                            let podNode = server.podArray[i];
                            for (let j = 0; j < podNode.cpu; j++) {
                                totalCpu += 1;
                                nodeCpus += 1;
                                items.push(
                                    <>
                                        <Popup
                                            id={j}
                                            content={podNode.name}
                                            key={index + podNode.name}
                                            trigger={
                                                <Table.Cell
                                                    className="thdata"
                                                    style={{ backgroundColor: podNode.color }}
                                                >
                                                    {podNode.id}
                                                </Table.Cell>
                                            }
                                        />
                                    </>
                                );

                            }

                        }
                        for (let k = 0; k < (workerCPU - totalCpu); k++) {
                            items.push(<Table.Cell id={k} className="thdata">-</Table.Cell>)

                        }
                        return items;
                    })()
                }
                <Table.Cell className="thdata" style={{ fontSize: "0.9rem" }}>
                    {server.memLeft}
                </Table.Cell>
                <Table.Cell className="thdata" style={{ fontSize: "0.9rem" }}>
                    {server.diskLeft}
                </Table.Cell>
            </Table.Row>
        );
    };
    return (
        <>
            <div>
                <Table celled striped fixed id="table1">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell className="thinfo titles thblack" colSpan="1">
                                Workers ({workers.length})
                            </Table.HeaderCell>
                            <Table.HeaderCell className="thinfo titles thblack" colSpan="1">
                                Anti-Affinity-Group
                            </Table.HeaderCell>
                            {workers.length ? (
                                <>
                                    <>
                                        <Table.HeaderCell
                                            className="titles thblack"
                                            colSpan={colSpan | workerCPU}
                                        >
                                            PODS ({workerCPU})
                                        </Table.HeaderCell>
                                    </>


                                    <Table.HeaderCell className="titles thblack thinner">
                                        RAM Left (GB)
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className="titles thblack thinner">
                                        Disk Left (GB)
                                    </Table.HeaderCell>
                                </>
                            ) : ''}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {workers.map((server, index) => {
                            return renderTableRow(server, index);
                        })}
                    </Table.Body>
                </Table>
            </div>
            <div style={{
                marginTop: '40px'
            }}>
                {
                    servers && servers.length > 0 ?
                    <VNFLayout cputopNew={cputopNew} servers={servers}/> : ''
                }

            </div>
        </>
    )
}
