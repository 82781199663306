import React, { useState } from 'react'
import { Checkbox, Form, Header, Input, Label } from 'semantic-ui-react';
import InputWrapper from '../layout/InputWrapper';

export default function VNFInputs({
    numaNodes,
    setNumaNodes,
    cputop,
    setCputop,
    rescpu,
    setRescpu,
    HT,
    setHT,
    mem,
    setMem,
    disk,
    setDisk,
    sriovbw,
    setSriovbw,
    ports,
    setPorts,
    portsSwitch,
    setPortsSwitch,
    freeServers,
    setFreeServers
}
) {


    return (
        <div style={{ fontSize: "17px", padding: "1.3rem", borderRadius: "8px", margin: "10px 0px 10px 0px", backgroundColor: "#fff" }}>
            <Header>
                Server parameters
            </Header>

            <Form style={{ marginTop: "1rem", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                <InputWrapper>
                    <Label
                        basic
                        size={"large"}
                        style={{ borderWidth: 0, padding: "0 0.5rem" }}
                    >
                        NUMA Nodes
                    </Label>
                    <Input
                        list="numaNodes"
                        placeholder="Nodos"
                        style={{ width: "5rem", margin: "5px 0" }}
                        value={numaNodes}
                        onChange={e => setNumaNodes(e.target.value)}
                    />
                    <datalist id="numaNodes">
                        <option value="1">1 node</option>
                        <option value="2">2 nodes</option>
                        <option value="4">4 nodes</option>
                    </datalist>
                </InputWrapper>
                <InputWrapper>
                    <Label
                        basic
                        size={"large"}
                        style={{ borderWidth: 0, padding: "0 0.5rem" }}
                    >
                        CPUs/node
                    </Label>
                    <Input
                        list="pCPUs"
                        placeholder="pCPUs"
                        style={{ width: "5rem", margin: "5px 0" }}
                        value={cputop}
                        onChange={e => setCputop(e.target.value)}
                    />
                    <datalist id="pCPUs">
                        <option value="12">12</option>
                        <option value="14">14</option>
                        <option value="18">18</option>
                        <option value="20">20</option>
                        <option value="22">22</option>
                        <option value="24">24</option>
                        <option value="26">26</option>
                        <option value="32">32</option>
                        <option value="64">64</option>
                    </datalist>
                </InputWrapper>
                <InputWrapper>
                    <Label
                        basic
                        size={"large"}
                        style={{ borderWidth: 0, padding: "0 0.5rem" }}
                    >
                        Reserved CPUs/node
                    </Label>
                    <Input
                        list="pCPUsreserved"
                        placeholder="reservados"
                        style={{ width: "5rem", margin: "5px 0" }}
                        value={rescpu}
                        onChange={e => setRescpu(e.target.value)}
                    />
                    <datalist id="pCPUsreserved">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="4">4</option>
                    </datalist>
                </InputWrapper>
                <InputWrapper>
                    <Label
                        basic
                        size={"large"}
                        style={{ borderWidth: 0, padding: "0 0.5rem" }}
                    >
                        HT
                    </Label>
                    <Checkbox toggle checked={HT} onChange={() => setHT(!HT)} />
                </InputWrapper>
                <InputWrapper>
                    <Label
                        basic
                        size={"large"}
                        style={{ borderWidth: 0, padding: "0 0.5rem" }}
                    >
                        Memory(GB)
                    </Label>
                    <Input
                        style={{ width: "5rem", margin: "5px 0" }}
                        value={mem}
                        onChange={e => setMem(e.target.value)}
                    />
                </InputWrapper>
                <InputWrapper>
                    <Label
                        basic
                        size={"large"}
                        style={{ borderWidth: 0, padding: "0 0.5rem" }}
                    >
                        Disk(GB)
                    </Label>
                    <Input
                        style={{ width: "5rem", margin: "5px 0" }}
                        value={disk}
                        onChange={e => setDisk(e.target.value)}
                    />
                </InputWrapper>
                <InputWrapper>
                    <Label
                        basic
                        size={"large"}
                        style={{ borderWidth: 0, padding: "0 0.5rem" }}
                    >
                        SR-IOV/node(Gbps)
                    </Label>
                    <Input
                        style={{ width: "5rem", margin: "5px 0" }}
                        value={sriovbw}
                        onChange={e => setSriovbw(e.target.value)}
                    />
                </InputWrapper>
                <InputWrapper>
                    <Label
                        basic
                        size={"large"}
                        style={{ borderWidth: 0, padding: "0 0.5rem" }}
                    >
                        NICs/server
                    </Label>
                    <Input
                        style={{ width: "4rem", margin: "5px 0" }}
                        value={ports}
                        onChange={e => setPorts(e.target.value)}
                    />
                </InputWrapper>
                <InputWrapper>
                    <Label
                        basic
                        size={"large"}
                        style={{ borderWidth: 0, padding: "0 0.5rem" }}
                    >
                        Ports/switch
                    </Label>
                    <Input
                        style={{ width: "4rem", margin: "5px 0" }}
                        value={portsSwitch}
                        onChange={e => setPortsSwitch(e.target.value)}
                    />
                </InputWrapper>
                <InputWrapper>
                    <Label
                        basic
                        size={"large"}
                        style={{ borderWidth: 0, padding: "0 0.5rem" }}
                    >
                        Free cabled servers
                    </Label>
                    <Input
                        style={{ width: "4rem", margin: "5px 0" }}
                        value={freeServers}
                        onChange={e => setFreeServers(e.target.value)}
                    />
                </InputWrapper>

            </Form>
        </div>
    )
}

