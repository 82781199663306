import React from 'react';
import { Table } from "semantic-ui-react";

export default function CNFInfra({ numWorkers, numServers, externalStorage }) {
    return (
        <Table celled striped fixed>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell className="thinfo titles thblack" colSpan="3">
                        Infrastructure required
                    </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell className="thinfo titles thblack" colSpan="1">
                        Workers
                    </Table.HeaderCell>
                    <Table.HeaderCell className="thinfo titles thblack" colSpan="1">
                        Servers
                    </Table.HeaderCell>
                    <Table.HeaderCell className="thinfo titles thblack" colSpan="1">
                        External Storage (TB)
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell className="thinfo tdstrong">{numWorkers}</Table.Cell>
                    <Table.Cell className="thinfo tdstrong">{numServers}</Table.Cell>
                    <Table.Cell className="thinfo tdstrong">{(externalStorage / 1000).toFixed(2)}</Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    );
}
