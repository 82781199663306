import React, { useState } from 'react'

import CNFInputs from './CNFInputs';
import CSVReaderComponent from '../CSVReaderComponent';
import { colorArray } from "../../assets/styles/colors";
import style from "../../assets/styles/responsive.module.css";
import {
    Form,
    Popup,
    Label,
    Input,
    Checkbox,
    Button,
    Table,
    Header
} from "semantic-ui-react";
import { CSVReader } from 'react-papaparse';

const buttonRef = React.createRef();

export default function CNFCalculator({ buttonRef, onWorkersChange, onWorkerDataChange, cnfs,
    workers, workerData, setError,
    setCputopNew,
    setServers, sriovBwInput,
    setExternalStorage, numaNodes, cpuTop, rescpu, HT,
    serverMemInput, serverDiskInput, setVnfCputopNew, onDeleteError }) {

    const CINDER_STORAGE_ID = 1

    const uniqueVNFsNames = []

    const handleDeleteError = () => {
        onDeleteError();
    }

    const handleWorkersChange = (workers) => {
        onWorkersChange(workers)
    }

    const validateAntiAffinity = (antiAffinityList, vnfAntiAffinity) => {

        const arrayvnfAntiAffinity = vnfAntiAffinity.split(",")

        return antiAffinityList.some(item => arrayvnfAntiAffinity.includes(item))
    }

    const validatePayload = () => {
        const parameters = [
            'id', 'cnf', 'name', 'number', 'cpus', 'ram', 'anti_affinity', 'anti_affinity_group', 'local_disk', 'external_disk'
        ];

        const input = Object.keys(cnfs[0].data);
        for (let i = 0; i < parameters.length; i++) {
            if (!input.includes(parameters[i])) {
                return false;
            }

        }
        return true;
    }

    const handleWorkerDataChange = (event) => {
        onWorkerDataChange(event)
    }

    const generateAntiAffinityGroupList = (antiAffinityGroup, podType) => {
        let groups = antiAffinityGroup.split(',')
        const antiAffinityGroupList = []
        for (let g of groups) {
            antiAffinityGroupList.push({
                group: g,
                podType: podType
            })
        }
        return antiAffinityGroupList;
    }

    const placement = (cnfs, perWorkerCpu, memInput, diskInput
    ) => {

        const calculateWorkers = [];
        let calculatedExternalDisk = 0;
        const colors = colorArray
            .sort(() => 0.5 - Math.random())
            .slice(0, cnfs.length);

        // Loop through each pod type in all CNFs
        cnfs.forEach((cnfData, index) => {

            const cnf = cnfData.data;

            // Build array for counting unique CNFs
            if (cnf.vnf && !uniqueVNFsNames.includes(cnf.cnf)) {
                uniqueVNFsNames.push(cnf.cnf);
            }
            // CPU required by CNF
            const cnfCPU = parseInt(cnf.cpus) / 1000;
            // Memory required by CNF
            const cnfMem = parseInt(cnf.ram);
            // Disk required by CNF
            const cnfDisk = cnf.local_disk ? parseInt(cnf.local_disk) : 0;
            // SRIOV required by CNF
            const cnfSriov = cnf.sriov_throughput != "" && cnf.sriov_throughput && parseInt(cnf.sriov_throughput) != NaN ? parseInt(cnf.sriov_throughput) : 0;

            const cnfAntiAffinity = parseInt(cnf.anti_affinity) != NaN && parseInt(cnf.anti_affinity) > 0


            // for each CNF replica in CNF type
            for (let i = 1; i < parseInt(cnf.number) + 1; i++) {

                // build  object TODO:Understand
                const podObject = {
                    id: cnf.id,
                    name: cnf.cnf + "-" + cnf.name + "-" + i,
                    cpu: cnfCPU,
                    mem: cnfMem,
                    // disk: cnfDisk,
                    color: colors[index]
                };

                let foundWorker = false;
                // try to place it in an existing worker
                if (calculateWorkers.length) {
                    for (let j = 0; j < calculateWorkers.length; j++) {

                        // Anti affinity group restriction
                        let canBeScheduled = true;
                        const antiGroups = cnf.anti_affinity_group.split(',');

                        // Double for because one CNF can have more than one anti affinity group
                        for (let k = 0; k < antiGroups.length; k++) {
                            // Filter all groups on worker that match the current cnf anti affinity groups
                            const groupsToCheck = calculateWorkers[j].antiAffinityList.filter(g => g.group == antiGroups[k]);

                            // Check if some group does not belongs to same podtype/cnf_name
                            // If same podtype, then cnf can be scheduled, otherwise it can't, because exists
                            // another podtype with same anti affinity group
                            for (let g in groupsToCheck) {
                                if (groupsToCheck[g].podType != cnf.name) {
                                    canBeScheduled = false;
                                    break
                                }
                            }
                            if (!canBeScheduled) {
                                break;
                            }
                        }
                        if (!canBeScheduled) {
                            continue;
                        }

                        if (
                            cnfCPU <= calculateWorkers[j].cpusLeft &&
                            cnfMem <= calculateWorkers[j].memLeft &&
                            cnfDisk <= calculateWorkers[j].diskLeft &&
                            ( !cnfSriov && cnfSriov <= sriovBwInput - calculateWorkers[j].sriovBwRequired )&&
                            (!cnfAntiAffinity || calculateWorkers[j].podArray.find(el => el.name.includes(cnf.cnf) && el.name.includes(cnf.name)) == undefined)
                        ) {
                            // Computing available, place it!
                            if (parseFloat(cnf.external_disk) != NaN && parseFloat(cnf.external_disk) != undefined && parseFloat(cnf.external_disk) > 0 &&
                                workerData.externalDisk == CINDER_STORAGE_ID &&
                                calculateWorkers[j].podsWithExternalStorage > 26) {
                                podObject.name += " W/O External Storage"
                            } else if (parseFloat(cnf.external_disk) != NaN && parseFloat(cnf.external_disk) != undefined && parseFloat(cnf.external_disk) > 0) {

                                // Add to external disk
                                calculatedExternalDisk += parseFloat(cnf.external_disk);
                                calculateWorkers[j].podsWithExternalStorage = calculateWorkers[j].podsWithExternalStorage + 1;
                                podObject.name += " W External Storage"
                            } else {
                                podObject.name += " W/O External Storage"

                            }
                            calculateWorkers[j].podArray.push(podObject)

                            // Mark server with anti-affinity group
                            if (cnf.anti_affinity_group) {
                                const antiAffinityGroupList = generateAntiAffinityGroupList(cnf.anti_affinity_group, cnf.name);
                                antiAffinityGroupList.forEach(e => {
                                    if (!calculateWorkers[j].antiAffinityList.find(item => item.group == e.group && item.podType == e.podType)) {
                                        calculateWorkers[j].antiAffinityList.push(e);

                                    }
                                })

                            }
                            // Update CPUs left
                            calculateWorkers[j].cpusLeft -= cnfCPU;
                            // Update Mem left
                            calculateWorkers[j].memLeft -= cnfMem;
                            // Update Disk left
                            calculateWorkers[j].diskLeft -= cnfDisk;
                            // Update SRIOV required
                            calculateWorkers[j].sriovBwRequired += cnfSriov;

                            // Found NUMA for this VM!
                            foundWorker = true;
                            // Break NUMA loop
                            break;
                        }
                    }
                    // end of existing servers loop
                }

                // if no existing worker found, place it in a new worker
                if (!calculateWorkers.length || !foundWorker) {

                    let podArray = [];
                    if (cnf.external_disk) {
                        calculatedExternalDisk += parseFloat(cnf.external_disk);
                        podObject.name += " W External Storage"
                    } else {
                        podObject.name += " W/O External Storage"

                    }
                    podArray.push(podObject)
                    if (cnfCPU > perWorkerCpu) {
                        setError('Not enough CPUs')
                        throw new Error('Not enough CPUs');
                    }
                    if (cnfMem > memInput) {
                        setError('Not sufficient worker memory')
                        throw new Error('Not sufficient worker memory');
                    }
                    if (cnfSriov > sriovBwInput) {
                        setError('Not sufficient SR-IOV')
                        throw new Error('Not sufficient SR-IOV');
                    }
                    // add POD to new worker
                    calculateWorkers.push({
                        antiAffinityList: cnf.anti_affinity_group ? generateAntiAffinityGroupList(cnf.anti_affinity_group, cnf.name) : [],
                        podArray: podArray,
                        cpusLeft: perWorkerCpu - cnfCPU,
                        memLeft: memInput - cnfMem,
                        diskLeft: diskInput - cnfDisk,
                        sriovBwRequired: cnfSriov || 0,
                        podsWithExternalStorage: parseFloat(cnf.external_disk) != NaN && parseFloat(cnf.external_disk) != undefined && parseFloat(cnf.external_disk) > 0 ? 1 : 0
                    });

                    // Add to external disk


                }
                // end of POD loop
            }
            // End of CNF lopp
        });
        const perNUMAVCPU = (cpuTop - rescpu) * (HT ? 2 : 1);
        try {

            serversCalculation(calculateWorkers.length, numaNodes, perNUMAVCPU, serverMemInput, serverDiskInput, calculateWorkers)
        } catch (e) {
            console.log(e)
            handleWorkersChange([])
            return;
        }
        handleWorkersChange(calculateWorkers);

        // Pass variable by value, required to avoid CNF infra to reload when CPU parameter
        // on CNF inputs change
        setExternalStorage(calculatedExternalDisk)
        handleWorkerDataChange({ target: { value: workerData.vCPUs, name: 'totalvCPUs' } })

        return {
            workers: calculateWorkers,
            externalStorage: calculatedExternalDisk
        };
    };

    const serversCalculation = (
        workersLength,
        numaNodesInput,
        perNUMAVCPUinput,
        memInput,
        diskInput,
        calculatedWorkers
    ) => {

        let workers = Array(workersLength);
        workers = calculatedWorkers.map((item, index) => {

            return ({
                cpu: workerData.vCPUs, mem: workerData.mem, disk: workerData.disk, sriovbw: item.sriovBwRequired || null
            })
        })
        const calculatedServers = [];
        let calculatedExternalDisk = 0;
        const colors = colorArray
            .sort(() => 0.5 - Math.random())
            .slice(0, workers.length);

        workers.forEach((worker, index) => {

            const vmCPU = parseInt(worker.cpu);
            const vmSRIOVBW = parseInt(worker.sriovbw);
            const vmMem = parseInt(worker.mem);
            const vmDisk = worker.disk
            // for each VM in VNF

            // build VM object
            const vmObject = {
                id: worker.id,
                name: "worker-" + (index + 1),
                color: colors[index]
            };

            let foundServer = false;
            let foundNuma = false;
            // try to place it in an existing server
            if (calculatedServers.length) {
                for (let j = 0; j < calculatedServers.length; j++) {
                    // Affinity not on this server, try placement in each NUMA node
                    for (let k = 0; k < calculatedServers[j].numaArray.length; k++) {
                        if (
                            vmCPU <= calculatedServers[j].numaArray[k].cpusLeft &&
                            vmMem <= calculatedServers[j].memLeft &&
                            vmDisk <= calculatedServers[j].diskLeft &&
                            (!vmSRIOVBW || vmSRIOVBW <= calculatedServers[j].numaArray[k].sriovbwLeft)
                        ) {
                            calculatedServers[j].antiAffinityList.push(j)
                            // Computing available, place it!
                            calculatedServers[j].numaArray[k].cpus = calculatedServers[
                                j
                            ].numaArray[k].cpus.concat(Array(vmCPU).fill(vmObject));
                            // Mark server with anti-affinity group

                            // Update CPUs left
                            calculatedServers[j].numaArray[k].cpusLeft -= vmCPU;
                            // Update SRIOV BW left
                            if (vmSRIOVBW) {
                                calculatedServers[j].numaArray[k].sriovbwLeft -= vmSRIOVBW;
                            }
                            // Update Mem left
                            calculatedServers[j].memLeft -= vmMem;
                            // Update Disk left
                            calculatedServers[j].diskLeft -= vmDisk;
                            // Found NUMA for this VM!
                            foundNuma = true;
                            // Break NUMA loop
                            break;
                        }
                    }
                    // end of NUMA loop
                    if (foundNuma === true) {
                        // Found server for this VM!
                        foundServer = true;
                        // Break server loop
                        break;
                    }
                }
                // end of existing servers loop
            }
            // if no existing server-numa found, place it in a new server
            if (!calculatedServers.length || !foundServer) {

                // Validations
                if (vmCPU > perNUMAVCPUinput) {
                    setError('Not enough server CPUs')
                    return;
                }
                if (vmMem > memInput) {
                    setError('Not sufficient server memory')
                    return;
                }
                if (vmDisk > diskInput) {
                    setError('Not sufficient server disk')
                    return;
                }
                let numaArray = [];
                for (let i = 0; i < numaNodesInput; i++) {
                    numaArray.push({
                        cpus: [],
                        cpusLeft: perNUMAVCPUinput,
                        sriovbwLeft: parseInt(sriovBwInput)
                    })
                }


                numaArray[0].cpus = Array(vmCPU).fill(vmObject);
                numaArray[0].cpusLeft = perNUMAVCPUinput - vmCPU;

                // TODO: Add SR-IOV logic
                if (vmSRIOVBW) {
                    if (vmSRIOVBW > numaArray[0].sriovbwLeft) {
                        setError(
                            "No sufficient SR-IOV Bandwidth available, could not complete mapping."
                        );
                        setServers([]);
                        throw new Error("No sufficient SR-IOV Bandwidth available, could not complete mapping.");
                    }
                    numaArray[0].sriovbwLeft = parseInt(sriovBwInput) - vmSRIOVBW;
                }
                // add NUMA with VM to new server
                calculatedServers.push({
                    numaArray,
                    memLeft: memInput - vmMem,
                    diskLeft: diskInput - vmDisk,
                    antiAffinityList: []
                });
            }
            // end of VM loop
            // end of VNF loop
        });



        calculatedServers.forEach(server => {
            server.numaArray.forEach(numaNode => {
                // numaNode.cpus = numaNode.cpus.concat(Array(2).fill("R"));
                numaNode.cpus = numaNode.cpus.concat(Array(numaNode.cpusLeft).fill(""));
            });
        });
        setVnfCputopNew(perNUMAVCPUinput);
        setServers(calculatedServers);
        return {
            servers: calculatedServers,
            externalStorage: calculatedExternalDisk
        };
    };



    const calculate = cnfs => {

        handleDeleteError();

        if (cnfs.length === 0) {
            setError(`Empty csv`);
            return;
        }
        const perWorkerCpu = workerData.vCPUs - workerData.reservedvCPUs;
        const perWorkerMem = workerData.mem - workerData.reservedMem;
        if (!validatePayload()) {
            setError(`Invalid csv`);
            return;
        }
        try {
            placement(cnfs, perWorkerCpu, perWorkerMem, workerData.disk)
        } catch (e) {
            console.log(e)
        }
    };

    return (
        <div className={style.calculator_container}>

            <CNFInputs
                handleDataChange={handleWorkerDataChange}
                workerData={workerData}
            />
            <Button
                style={{ width: "130px", margin: "1rem 0.5rem", padding: '0.9rem 0', display: 'none' }}
                positive
                ref={buttonRef}
                content="Calculate CNF"
                onClick={() => {
                    calculate(cnfs);
                }}
            />
        </div>
    )
}
