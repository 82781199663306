import React from "react";
import { Label, Button } from "semantic-ui-react";
import { CSVReader } from "react-papaparse";


const CSVReaderComponent = ({ uploadFile }) => {
  const buttonRef = React.createRef();
  const handleOpenDialog = e => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = data => {
    uploadFile(data);
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleOnRemoveFile = data => {
    console.log("---------------------------");
    console.log(data);
    console.log("---------------------------");
  };

  const handleRemoveFile = e => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
  };

  return (
    <div style={{ margin: "20px 0 20px 0"}}>
      <CSVReader

        ref={buttonRef}
        onFileLoad={handleOnFileLoad}
        noClick
        noDrag
        config={{ header: true }}
        style={{}}
        onRemoveFile={handleOnRemoveFile}
        onError={handleOnError}
      >
        {({ file }) => (
          <aside
            style={{ display: 'flex', alignItems: 'center'}}
          >
            <Button primary onClick={handleOpenDialog}>
              Upload NFs CSV
            </Button>
            {file && (
              <Label size={"large"} style={{ margin: "0 1rem" }}>
                File: {file.name}
              </Label>
            )}
            {/* {file && (
              <Button negative onClick={handleRemoveFile}>
                Remover
              </Button>
            )} */}
            <span style={{ display: 'flex', flexDirection: 'row',justifyContent: "center", alignItems: "center"}}>
              <a
                target="_blank"
                style={{ margin: '0 1rem 0 1rem'}}

                href={process.env.PUBLIC_URL + "/vnfs.csv"}
              >
                VNFs CSV File example
              </a>
              <a
                target="_blank"
                style={{ margin: '0 1rem 0 1rem'}}

                href={process.env.PUBLIC_URL + "/cnfs.csv"}
              >
                CNFs CSV File example
              </a>
            </span>
          </aside>
        )}
      </CSVReader>
    </div>
  );
};

export default CSVReaderComponent;
